
export default {
  'login.header': 'Sisäänkirjautuminen',
  'login.placeholder.username': 'Käyttäjätunnus',
  'login.placeholder.password': 'Salasana',
  'login.login': 'Kirjaudu',
  'invoicing.title': 'Laskutus',
  'nav.create': 'Uusi lasku',
  'nav.list': 'Laskut',
  'nav.list_customers': "Asiakkaat",
  'nav.logout': 'Kirjaudu ulos',
  'list.header': 'Laskut',
  'button.save': 'Tallenna',
  'button.cancel': 'Peruuta',
  'invoice.customer': 'Asiakas',
  'invoice.invoice_number': 'Laskun numero',
  'invoice.reference_number': 'Viitenumero',
  'invoice.created': 'Luotu',
  'invoice.due': 'Eräpäivä',
  'invoice.delivery': 'Toimitusehdot',
  'invoice.remarking_time': 'Huomautusaika',
  'invoice.remarking_time_days_suffix': 'päivää',
  'invoice.interest_on_arrears': 'Viivästyskorko',
  'invoice.conditions_of_payment': 'Maksuehdot',
  'invoice.customer_reference': 'Asiakkaan viite',
  'invoice.view.header': 'Lasku { invoiceNumber }',
  'invoice.information': 'Laskun tiedot',
  'invoice.form.add_item': 'Lisää rivi',
  'invoice.form.error.at_least_one_item_required': 'Laskulle vaditaan vähintään yksi rivi',
  'customer.information': 'Asiakastiedot',
  'customer.company': 'Yritys',
  'customer.additional_name': 'Nimen lisätieto',
  'customer.contact_person': 'Yhteyshenkilö',
  'customer.vat': 'Y-tunnus',
  'customer.phone': 'Puhelin',
  'customer.email': 'Sähköposti',
  'customer.street_name': 'Osoite',
  'customer.post_code': 'Postinumero',
  'customer.city': 'Kaupunki',
  'customers.list': 'Asiakkaat',
  'customers.search': 'Etsi asiakasta...',
  'invoice.items.header.name': 'Selite',
  'invoice.items.header.price': 'Hinta',
  'invoice.items.header.amount': 'Määrä',
  'invoice.items.header.tax': 'ALV %',
  'invoice.total': 'Yhteensä',
  'invoice.print': 'Tulosta',
  'invoice.send_email': 'Lähetä lasku asiakkaan sähköpostiin',
  'invoice.new': 'Uusi lasku',
  'invoice.edit': 'Muokkaa',
  'invoice.select_customer.selected': 'Valittu',
  'invoice.select_customer.create_new': 'Luo uusi asiakas',
  'invoice.select_customer.creating_new': 'Luodaan uusi asiakas',
  'invoice.select_customer.not_selected': 'Ei valittu',
  'invoice.select_customer.customer_name': 'Asiakkaan nimi',
  'invoice.select_customer.not_found': 'Asiakasta ei löytynyt',
  'invoice.select_customer.remove_selection': 'Poista valinta',
  'invoice.select_template': 'Valitse laskupohja',
  'invoice.template': 'Laskupohja'
}
